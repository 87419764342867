import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Suno03Activity07Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [totalStar, setTotalStar] = useState();
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');
  const [isDisabled, setIsDisabled] = useState(true);
  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  useEffect(() => {

    var data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "suno",
      "activity_no": "3"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        console.log(response.data.data)
        if (response.data.success === true) {
          const result = response.data.data.total_stars;
          setTotalStar(result);

        }



      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      handleShow();
      setIsDisabled(false);
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  // retry

  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/suno-03-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "suno",
          "activity_key_no": "suno03",
          "activity_no": "3"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            'type': `${userType} `,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }





  const ShowMsg = () => {
    if (totalStar > 0) {
      return (
        <>
          <h1
            className="bahut-khub mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            शाबाश!
          </h1>
          <div
            className="six-tare mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h1 className="rh1">सभी जवाब के सही उत्तर दिए हैं</h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>{totalStar}</h4>
              </li>
            </ul>
          </div>
        </>
      )
    } else {
      return (
        <>


          <div
            className="six-tare mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h1 className="rh1 red">गलत उत्तर है</h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>{totalStar}</h4>
              </li>
            </ul>
          </div>
        </>
      )
    }
  }


  return (
    <>
      <main>
        <section className="bg-flexible D4F3FD">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center mt-30">
                <div className="col-lg-4">
                  <ShowMsg />
                  {/* <div class="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                      <img class="mt-10" src="assets/img/suno/sabas-tare-10.svg" alt="">
                  </div> */}
                  <div className="gkw-img d-flex mt-30">
                    {/* <div
                    className="mr-20  wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <a href="stss-gyaan-box.html">
                      <center>
                        <img src="assets/img/icon/gyan-box.svg" alt="" />
                      </center>
                      <p className="side-btn">ज्ञान बॉक्स</p>
                    </a>
                  </div> */}
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >


                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>

                      </div>
                    </div>
                    <div>
                      <div
                        className=""

                      >
                        <NavLink to="/board-screen" className="btnset ">
                          <span>अगली एक्टिविटी </span>{" "}
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mt-0"
                      width="90%"
                      src="assets/img/suno/suno-img-2.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">



                <div className="row align-items-center justify-content-between mt-50">
                  <div
                    className="col-lg-7 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <p className="mpara large-para fs-30 mt-40">
                      {/* हम कहते कुछ हैं और दूसरा समझता कुछ और है इसलिए हम जो बोलें, साफ़ बोलें और
                      ध्यान से सुनेंं। अगर हम ठीक से सुनेंंगे नहीं तो बहुत समस्याएँ हो सकती हैं।
                      जैसे कि किसी के बारे में कुछ कह दिया और जब तक सुनने वाले तक बात पहुँची, तो
                      बड़ी बात बन जाए। तो ऐसे में झगड़ा भी हो सकता है। */}

                      जैसा हमने अभी देखा कि ध्यान से सुनना कितना ज़रूरी है, बातों को सही से समझने के लिए | अगर हम ध्यान से नहीं सुनेंंगे तो बहुत सी समस्याएं हो सकती हैं जैसा कि सुना कुछ और, कह कुछ और दिया | जब ये बात आगे बढ़ती है तो बात का बतंगड़ बन सकता है, ऐसे में झगड़ा या ग़लतफहमी भी हो सकती है, इसलिए जो भी सुनें  ध्यान से सुनें और जो भी बोलें साफ़ स्पष्ट बोलें  और  अगर कुछ न समझ आये तो उसे दुबारा पूछें भी



                    </p>
                  </div>
                  <div
                    className="col-lg-4 offset-lg-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.5s"
                  >
                    <NavLink to="#" className="width-auto">
                      <center>
                        <img
                          draggable="false"
                          className="mb-15"
                          width="100%"
                          src="assets/img/suno/suno-img-2.svg"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>



              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Suno03Activity07Screen;
