import React, { useEffect, useState } from "react";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import Name from "../common/Name";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Khelo08Activity31Screen = () => {

  const navigate = useNavigate();
  const [item, setItem] = useState([]);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');
  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');
const [isDisabled, setIsDisabled] = useState(true);

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity08AllResult",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setItem(response.data.data);
        }
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      setIsDisabled(false);
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-08-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "khelo",
          "activity_key_no": "khelo08",
          "activity_no": "8"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            "type":`${userType}`,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }


  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = async()=>{
    var config = {
      method: "post",
      url: BaseURL+"/kheloFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    await axios(config)
      .then(function (response112) {

        let sum  = parseInt(kheloIndex) + parseInt(1);
        if(userType == 'group')
        {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }

        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{

    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      // localStorage.setItem('khelo', sum);
      // navigate(kheloFacilatorUrl[sum].url);

      if(kheloFacilatorUrl[sum] != undefined)
      {
        localStorage.setItem('khelo', sum);

        navigate(kheloFacilatorUrl[sum].url);
      }else{
        navigate('/board-screen');
      }

    } else {
      navigate('/khelo-09-activity-01-screen')
    }
  }





  return (
    <>
      <main>
        <section className="bg-flexible F0F4FF">
          <div className="fixed-header">
            <div className="container-fluid cf-set">
              <Name />
            </div>
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                  <div
                    className="six-tare mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1">
                      {item.totalCorrectQues} सही उत्तर दिए हैं
                    </h1>
                    <p className="text-center">तारे इकट्ठेे हुए</p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{item.totalstar}</h4>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex mt-30">
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >


                        <NavLink onClick={retryActivity} to="#">
                                  <center>
                                    <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                                  </center>
                                  <p className="side-btn">दुबारा कोशिश   करें</p>
                                </NavLink>

                      </div>
                    </div>
                    <div>
                      <div
                        className=" wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.4s"
                      >
                        {/* <button
                        onClick={nextUrlLink}

                          className="btnset "
                        >
                          <span>अगली एक्टिविटी </span>{" "}
                        </button> */}


                        <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                        {" "}
                        {isDisabled ? <span>अगली एक्टिविटी </span> : <span>अगली एक्टिविटी </span>}
                      </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/icon/kkkn-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup pt-m-3">
              <div className="">

                <div className="data-adjust">
                  <div className="container-fluid cf-set">
                    <div className="row align-items-center justify-content-center ">
                      <div
                        className="col-lg-6 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.1s"
                      >
                        <p className="mpara large-para fs-30 ">
                          क्या आपको पूरे 14 तारे मिले? अगर हाँ तो बहुत ही बढ़िया
                          बात है। आप अपने दोस्तों, घरवालों को भी यह समझा सकते हो
                          और यदि आपको पूरे तारे नहीं मिले तो आपके लिए यह समझना
                          ज़रूरी है कि इस लिस्ट में दिए गए कार्य कोई भी कर सकता
                          है, लड़का हो या लड़की। कोई भी कार्य छोटा या बड़ा नहीं है
                          दोनों ही ये सारे काम कर सकते हैं
                        </p>
                      </div>
                      <div
                        className="col-lg-4 offset-lg-1 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.1s"
                      >
                        <center>
                          <img
                            draggable="false"
                            width="95%"
                            src="assets/img/khelo/icon/kkkn-main-img.svg"
                          />
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo08Activity31Screen;
