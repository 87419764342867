import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Karo03Activity09Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [totalStar, setTotalStar] = useState();
  const [isDisabled, setIsDisabled] = useState(true);

  const [karoFacilatorUrl, setKaroFacilatorUrl] = useState([]);
  const karoIndex = localStorage.getItem('karo');

  const user_details = JSON.parse(localStorage.getItem("user_details"));
    const groupId = localStorage.getItem('groupId');

    let userType = 'user';
    let user_id = user_details.user_id;
    if(user_details.role_id == 16)
    {

      userType = 'group';
      user_id =groupId;
    }

  useEffect(() => {

    const data = JSON.stringify({
      user_id: user_id,
      "activity_type": "karo",
      "activity_no": "3"
    });

    const config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          setTotalStar(response.data.data.total_stars);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {

    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      setIsDisabled(false);
      // navigate("/gyanbox-03-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

// retry

  const retryActivity = ()=>{
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएंगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/karo-03-activity-01-screen');
      }
    })
  }



  const retryActivityApi = ()=>{

        let data = JSON.stringify({
          "user_id": user_id,
          "activity_type": "karo",
          "activity_key_no": "karo03",
          "activity_no": "3"
        });

      const config = {
          method: "POST",
          url: BaseURL + "/retryActivity",
          headers: {
            'Authorization': `Bearer ${user_details.token} `,
            'type': `${userType} `,
            'Content-Type': 'application/json'
          },
          data: data,
        };


      axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }



  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = async()=>{
    var config = {
      method: "post",
      url: BaseURL+"/karoFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    await axios(config)
      .then(function (response112) {


        if(userType == 'group')
        {
          setKaroFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{
    const sum = parseInt(karoIndex)+1;

    if(userType == 'group')
    {
      // localStorage.setItem('karo', sum);
      // navigate(karoFacilatorUrl[sum].url);

      if(karoFacilatorUrl[sum] != undefined)
      {
        localStorage.setItem('karo', sum);

        navigate(karoFacilatorUrl[sum].url);
      }else{
        navigate('/board-screen');
      }

    }else{
      navigate('/karo-04-activity-01-screen')
    }

  }



  return (
    <>
      <main>
        <section className="bg-flexible gerua">
            <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
            <div class="data-adjust">
          <div className="container-fluid cf-set">

            <div className="row align-items-center justify-content-center mt-30">
              <div className="col-lg-5">
                <div
                  className="six-tare wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h1 className="rh1 green">
                    {/* सफलतापूर्वक  */}
                    जगह को साफ़  करने के लिए और फोटो अपलोड करने पर आपने {totalStar} तारे इकट्ठे

                    किये{" "}
                  </h1>
                  <ul className="ul-r">
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <img src="assets/img/icon/star.svg" alt="" />
                    </li>
                    <li>
                      <h4>{totalStar}</h4>
                    </li>
                  </ul>
                </div>

                <div className="d-flex mt-30 gkw-img ">
                  <div className="mr-20">
                    <div
                      className="d-flex"

                    >
                      <NavLink onClick={retryActivity} to="#">
                        <center>
                          <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                        </center>
                        <p className="side-btn">दुबारा कोशिश   करें</p>
                      </NavLink>
                    </div>
                  </div>
                  {/* <div className="mr-20">
                               <NavLink to="/gyanbox-03-activity-screen">
                                   <center><img src="assets/img/icon/gyan-box.svg" alt="" /></center>
                                   <p className="side-btn">ज्ञान बॉक्स</p>
                               </NavLink>

                           </div> */}
                  <div>
                    <div
                      className=""

                    >
                      {/* <button
                        onClick={nextUrlLink}
                        className="btnset "
                      >
                        <span>अगली एक्टिविटी</span>{" "}
                      </button> */}

                      <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                         {" "}
                          {isDisabled ? <span>अगली एक्टिविटी  </span> :  <span>अगली एक्टिविटी </span>}
                        </button>

                    </div>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 offset-lg-1 wow fadeInDown"
                data-wow-duration="1s"
                data-wow-delay="0.4s"
              >
                <center>
                  <img
                    draggable="false"
                    width="100%"
                    src="assets/img/general/safai-girl.svg"
                  />
                </center>
              </div>
            </div>
          </div>
          </div>
        </section>
      </main>
      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp "
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">

                <div className="row align-items-top justify-content-between mt-50">
                  <div
                    className="col-lg-6 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p className="mpara large-para gyanpara-large ">
                      कैसा लगा सफाई करके? आप सब यह कर सकते हैं, लड़का हो या लड़की।
                      आस-पास का माहौल मन की स्थिति को भी दर्शाता है तो बहुत
                      ज़रूरी है अपने आस-पास को भी साफ रखना। हमारे रहने की जगह को साफ़  करने के लिए और जगह को
                      साफ़ रखना हमारी ज़िम्मेदारी है - जैसे कि  हमारा कमरा, घर, गली
                      और मोहल्ला।
                    </p>
                    {/* <NavLink to="/karo-04-activity-01-screen" className="btnset "><span>आगे बढ़ें </span> </NavLink> */}
                  </div>
                  <div
                    className="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="#">
                      <center>
                        <img
                          draggable="false"
                          className="mb-15"
                          width="80%"
                          src="assets/img/general/safai-girl.svg"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Karo03Activity09Screen;
