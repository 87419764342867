import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Khelo07Activity17Screen = () => {

  const navigate = useNavigate();
  const [items, setItem] = useState([]);

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');
const [isDisabled, setIsDisabled] = useState(true);

  let userType = 'user';
  let user_id = user_details.user_id;
  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }




  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
    });

    var config = {
      method: "post",
      url: BaseURL + "/kheloActivity07AllResult",
      headers: {
        "type":`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setItem(response.data.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      setIsDisabled(false);
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const retryActivity = () => {
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-07-activity-01-screen');
      }
    })
  }



  const retryActivityApi = () => {

    let data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "khelo",
      "activity_key_no": "khelo07",
      "activity_no": "7"
    });

    const config = {
      method: "POST",
      url: BaseURL + "/retryActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        "type":`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data,
    };


    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }


  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = async()=>{
    var config = {
      method: "post",
      url: BaseURL+"/kheloFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    await axios(config)
      .then(function (response112) {


        if(userType == 'group')
        {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{

    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      // localStorage.setItem('khelo', sum);
      // navigate(kheloFacilatorUrl[sum].url);

      if(kheloFacilatorUrl[sum] != undefined)
      {
        localStorage.setItem('khelo', sum);

        navigate(kheloFacilatorUrl[sum].url);
      }else{
        navigate('/board-screen');
      }

    } else {
      navigate('/khelo-08-activity-01-screen')
    }

  }





  return (
    <>
      <main>
        <section className="bg-flexible FFF6E8">
          <div className="fixed-header">

            <Name />

          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                  <h1
                    className="bahut-khub mt-m-1 fs-m-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    शाबाश!
                  </h1>
                  <div
                    className="six-tare mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1">
                      {items.totalCorrectQues} सही उत्तर दिए हैं{" "}
                    </h1>
                    <p className="text-center"> तारे इकट्ठे हुए </p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{items.totalstar}</h4>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex mt-30">
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >


                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>

                      </div>
                    </div>
                    <div>
                      <div
                        className=" wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.4s"
                      >
                        {/* <button
                        onClick={nextUrlLink}

                          className="btnset "
                        >
                          <span>अगली एक्टिविटी </span>{" "}
                        </button> */}


                        <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                        {" "}
                        {isDisabled ? <span>अगली एक्टिविटी </span> : <span>अगली एक्टिविटी </span>}
                      </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/sh-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup pt-m-3">
              <div className="container-fluid">

                <div className="row align-items-center justify-content-center wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <div className="">
                    <div className="row mt-0">
                      <div
                        className="col-lg-12 "

                      >
                        <p className="mpara large-para fs-30">
                          मानवाधिकार हमें जन्म से मिलते हैं। हम सब बराबर हैं और
                          हम सब को अधिकार भी बराबर ही मिलने चाहिए। चाहे फिर हम
                          किसी भी जेंडर, लिंग, जाति, धर्म, वेशभूषा, रंग इत्यादि के हों ।
                        </p>
                        <p className="mpara large-para fs-30 ">
                          एक बार फिर से हम हमारे मानवाधिकारों पर नज़र डालते हैं
                        </p>
                      </div>
                    </div>
                    <div className="row mt-0">
                      <div
                        className="col-lg-2 "
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-1.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>सभी को आज़ादी और समानता का हक है</p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 "
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-2.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>भेदभाव से आज़ादी का हक है </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 "
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-3.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>जीने की आज़ादी का हक है </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 "
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-4.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>न्याय माँगने की आज़ादी का हक है </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-5.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>कानून के आगे समानता का हक है </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-6.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>घूमने-फिरने की आज़ादी का हक है </p>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div
                        className="col-lg-2"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-7.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>देश में कहीं भी रहने की आज़ादी का हक़ है</p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 "
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-8.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>खाने-पीने की आज़ादी का अधिकार है </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 "
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-9.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>नागरिकता की आज़ादी का हक है</p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-10.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>अपनी बात रखने की आज़ादी </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2"
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sha-11.png"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>
                          किसी भी व्यस्क व्यक्ति को शादी करने और परिवार बनाने का हक़ है
                          </p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 "
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-12.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>अपनी चीज़ें लेने और खरीदने की आज़ादी का हक है </p>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-20">
                      <div
                        className="col-lg-2 "
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-13.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>शिक्षा का अधिकार है</p>
                        </div>
                      </div>
                      <div
                        className="col-lg-2 "
                      >
                        <div className="dekho-gyan-box">
                          <div className="gyan-box-img">
                            <center>
                              <img
                                src="assets/img/khelo/icon/sh-14.svg"
                                alt=""
                              />
                            </center>
                          </div>
                          <p>राय और अभिव्यक्ति की स्वतंत्रता है</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo07Activity17Screen;
