import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import BaseURL from "../common/Baseurl";
import axios from "axios";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Khelo09Activity15Screen = () => {

  const [totalStar, setTotalStar] = useState(0);
  const users = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');
  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');
const [isDisabled, setIsDisabled] = useState(true);
  let userType = 'user';
  let user_id = users.user_id;
  if(users.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }


  const navigate = useNavigate();

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      "activity_type": "khelo",
      "activity_no": "9"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        "type": `${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setTotalStar(response.data.data.total_stars);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      setIsDisabled(false);
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  const retryActivity = () => {
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-09-activity-01-screen');
      }
    })
  }



  const retryActivityApi = () => {

    let data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "khelo",
      "activity_key_no": "khelo09",
      "activity_no": "9"
    });

    const config = {
      method: "POST",
      url: BaseURL + "/retryActivity",
      headers: {
        'Authorization': `Bearer ${users.token} `,
        "type":`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data,
    };


    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }



  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = async()=>{
    var config = {
      method: "post",
      url: BaseURL+"/kheloFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${users.token} `,
        'type':`${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    await axios(config)
      .then(function (response112) {


        if(userType == 'group')
        {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = ()=>{

    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      // localStorage.setItem('khelo', sum);
      // navigate(kheloFacilatorUrl[sum].url);

      if(kheloFacilatorUrl[sum] != undefined)
      {
        localStorage.setItem('khelo', sum);

        navigate(kheloFacilatorUrl[sum].url);
      }else{
        navigate('/board-screen');
      }

    } else {
      navigate('/khelo-10-activity-01-screen')
    }
  }



  return (
    <>
      <main>
        <section className="bg-flexible E8FEFF">
          <div className="fixed-header">
            <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div
                  className="col-lg-4 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <h1 className="rh1 mt-m-1 green text-left">
                    आपने बहुत अच्छे से मदद की अपने साथी की
                  </h1>
                  <div className="six-tare mt-30">
                    <p className="text-center">तारे इकट्ठेे हुए</p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{totalStar}</h4>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex mt-30">
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"
                      >

                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>

                      </div>
                    </div>
                    <div>
                      <div
                        className="gkw-img d-flex wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.3s"

                      >
                        {/* <button onClick={nextUrlLink}  className="btnset ">
                          <span>अगली एक्टिविटी </span>{" "}
                        </button> */}


                        <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                        {" "}
                        {isDisabled ? <span>अगली एक्टिविटी </span> : <span>अगली एक्टिविटी </span>}
                      </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="95%"
                      src="assets/img/khelo/bsbc-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup pt-m-3">
              <div className="container-fluid">

                <div className="data-adjust">
                  <div className="">
                    <div className="row align-items-center justify-content-center ">
                      <div
                        className="col-lg-6 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.1s"
                      >
                        <p className="mpara large-para fs-30">
                          लीडर बनने के गुण सब में होते हैं लेकिन किसी में
                          ज़्यादा, किसी में कम। इससे कोई फर्क नहीं पड़ता कि लीडर
                          लड़का है या लड़की, गुण तो सब में होते हैं।
                        </p>
                        <p className="mpara large-para fs-30">
                          अब चलिए जानते है की एक अच्छा लीडर बनने के लिए आपके अंदर ये गुण होने चाहिए:-
                        </p>
                        <p className="mpara large-para fs-30"> अच्छा लीडर बनने के लिए आपके अंदर ये गुण होने चाहिए:-</p>
                        <p className="mpara large-para fs-30">
                          <ul>
                            <li>* अपने साथियों के प्रति ईमानदार होना</li>
                            <li>* आत्मविश्वासी होना</li>
                            <li>* सुनने का कौशल</li>
                            <li>* निर्णय लेने की क्षमता</li>
                            <li>* साफ़, स्पष्ट और सोच समझकर बोलना</li>
                            <li>* सबको साथ लेकर चलना</li>
                            <li>* दूर की सोच रखने की कोशिश करना</li>
                          </ul>

                        </p>
                      </div>
                      <div
                        className="col-lg-4 offset-lg-1 wow fadeInDown"
                        data-wow-duration="1s"
                        data-wow-delay="0.2s"
                      >
                        <center>
                          <img
                            draggable="false"
                            width="95%"
                            src="assets/img/khelo/bsbc-main-img.svg"
                          />
                        </center>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Khelo09Activity15Screen;
