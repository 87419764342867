import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
// import "bootstrap/dist/css/bootstrap.css";

const Karo05Activity06Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-5");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center mt-50"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [totalStar, setTotalStar] = useState();
  const [karoFacilatorUrl, setKaroFacilatorUrl] = useState([]);
  const karoIndex = localStorage.getItem('karo');
  const [isDisabled, setIsDisabled] = useState(true);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;
  if (user_details.role_id == 16) {

    userType = 'group';
    user_id = groupId;
  }

  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      "activity_type": "karo",
      "activity_no": "5"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log(JSON.stringify(response.data));
        if (response.data.success === true) {
          setTotalStar(response.data.data.total_stars);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      setIsDisabled(false);
      handleShow();
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  // hint moal


  const handleShow1 = () => setShow1(true);
  const handleClose1 = () => setShow1(false);


  // retry

  const retryActivity = () => {
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/karo-05-activity-01-screen');
      }
    })
  }



  const retryActivityApi = () => {

    let data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "karo",
      "activity_key_no": "karo05",
      "activity_no": "5"
    });

    const config = {
      method: "POST",
      url: BaseURL + "/retryActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };


    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }

  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = async () => {
    var config = {
      method: "post",
      url: BaseURL + "/karoFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    await axios(config)
      .then(function (response112) {

        // console.warn(response112.data.facilator_permission);
        if (userType == 'group') {
          setKaroFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const nextUrlLink = () => {
    const sum = parseInt(karoIndex) + 1;

    if (userType == 'group') {
      // localStorage.setItem('karo', sum);
      // navigate(karoFacilatorUrl[sum].url);

      if (karoFacilatorUrl[sum] != undefined) {
        localStorage.setItem('karo', sum);

        navigate(karoFacilatorUrl[sum].url);
      } else {
        navigate('/board-screen');
      }

    } else {
      navigate('/karo-06-activity-01-screen')
    }

  }





  return (
    <>
      <main>
        <section className="bg-flexible blues">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">

            <div className="container">

              <div className="row align-items-center justify-content-center ">
                <div className="col-lg-4 col-md-12 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                  <h1 className="bahut-khub">
                    बहुत खूब!
                  </h1>
                  <div
                    className="six-tare"
                  >
                    <h1 className="rh1">आपकी राय जान कर अच्छा लगा </h1>
                    <p className="text-center"> तारे इकट्ठे हुए </p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{totalStar}</h4>
                      </li>
                    </ul>
                  </div>

                  <div className="d-flex mt-30 gkw-img">
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex"
                      >
                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>
                      </div>
                    </div>

                    <div className="mr-20 ">


                      <button onClick={handleShow1} >
                        <center><img src="assets/img/icon/gyan-box.svg" alt="" /></center>
                        <p className="side-btn">हिंट</p>
                      </button>

                    </div>

                    <div>
                      <div className="">
                        {/* <button onClick={nextUrlLink}

                        className="btnset "
                      >
                        <span>अगली एक्टिविटी </span>{" "}
                      </button> */}


                        <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                          {" "}
                          {isDisabled ? <span>अगली एक्टिविटी  </span> : <span>अगली एक्टिविटी </span>}
                        </button>




                      </div>



                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-4 col-md-12 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mb-15"
                      width="86%"
                      src="assets/img/karo/apsi-muthbhed.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">

                <div class="row align-items-top justify-content-center mt-40">
                  <div
                    class="col-lg-8 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p class="mpara large-para fs-30 mt-40">
                      हमारी भाषा, व्यवहार, और हमारे काम हमारे दोस्तों,
                      सहपाठियों, और बहन-भाइयों पर असर डालते हैं।
                    </p>
                    <p class="mpara large-para fs-30 mt-0">
                      यदि हम नकारात्मक सोच और बुरा व्यवहार रखें तो यह उनकी
                      भावनाओं को ठेस पहुँचा सकता है और निराशाजनक भी हो सकता है।{" "}
                    </p>
                    <p class="mpara large-para fs-30 mt-0">
                      तो हमें ख़ास ख्याल रखना है कि हम अपनी सोच और दूसरों के
                      प्रति अपना व्यवहार सकारात्मक रखें और किसी भी तरह उन्हें
                      नीचा दिखाने की कोशिश न करें।{" "}
                    </p>
                    {/* <h6>Strategy</h6> */}
                    <p class="mpara large-para fs-30 mt-0"> साथियों के दबाव से बचने के कुछ उपाय:</p>

                    <ul class="large-para fs-30 mt-0">
                      <li >- संतुष्ट होने तक अपने साथियों से प्रश्न पूछते रहें कि वह ऐसा क्यों कर रहे हैं, या उनके इस व्यव्हार से किसी और पर क्या असर होगा?</li>
                      <li>- दृढ़ता से न कहें कारण बताएं।  अगर वह न सुनें तो वहां से चले जाएँ </li>
                      <li>- उन गतिविधियों के लिए न कहना जारी रखें जिनका आप हिस्सा नहीं बनना चाहते हैं</li>
                      <li>- ऐसे व्यवहार के लिए न कहते रहिये जो आपको पसंद नहीं हैं </li>
                      <li>- न कहने का अभ्यास करें</li>
                      <li>- स्थिति से दूर हटें - किसी दूसरे कमरे या जगह पर चले जाएँ जहाँ आप सुरक्षित महसूस करते हैं।  </li>
                      <li>- अगर आप जानतें हैं कि आपके साथी आपको आपकी मर्जी के खिलाफ कुछ करने के लिए दबाव डालेंगे तो उस जगह/ या परिस्थिति में जाएँ ही नहीं      </li>
                      <li>- अपने बाकी साथियों से बात करें और उनकी मदद लें इन व्यव्हार/ स्थिति को रोकने के लिए  </li>
                      <li>- किसी भरोसेमंद व्यस्क से बात करें और अगर उनसे मदद मिल सकती है, तो मांगे</li>

                    </ul>






                  </div>
                  <div
                    class="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="mpm-activity-completion.html" class="width-p">
                      <center>
                        <img
                          draggable="false"
                          class="mb-15"
                          width="100%"
                          src="assets/img/karo/ghunsa.svg"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>



      {/* hint modal */}

      <Modal show={show1} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose1}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">

                <div class="row align-items-top justify-content-center mt-40">
                  <div
                    class="col-lg-8 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p class="mpara large-para fs-30 mt-40">
                      हिंसा - किसी भी प्रकार का कार्य या बात जो दूसरों को पीड़ा पहुँचाए  यह हिंसा है| किसी पर शासन करना, दूसरों को दास बनाना,  डराना , धमकाना, मारना, पीटना, गाली गलोच करना; अपने विचार, अपनी  मान्यताएं दूसरों पर थोपना; किसी का अनुचित फायदा उठाना, उन पर अपनी श्रेष्ठता जताना, यह सब हिंसा है| हिंसा किसी भी रूप व् हाल में जायज़ नहीं है|

                    </p>





                  </div>
                  <div
                    class="col-lg-4 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="mpm-activity-completion.html" class="width-p">
                      <center>
                        <img
                          draggable="false"
                          class="mb-15"
                          width="100%"
                          src="assets/img/karo/ghunsa.svg"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Karo05Activity06Screen;
