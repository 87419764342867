import React, { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";




const Karo06Activity05Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-3");
  const [totalStar, setTotalStar] = useState();
  const [karoFacilatorUrl, setKaroFacilatorUrl] = useState([]);
  const karoIndex = localStorage.getItem('karo');
const [isDisabled, setIsDisabled] = useState(true);

  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;

  if (user_details.role_id == 16) {

    userType = 'group';
    user_id = groupId;
  }

  useEffect(() => {


    const data = JSON.stringify({
      user_id: user_id,
      "activity_type": "karo",
      "activity_no": "6"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        if (response.data.status === "Token is Expired") {
          window.addEventListener("beforeunload", () => localStorage.removeItem('user_details'));
          navigate("/welcome");
        }

        if (response.data.success === true) {
          setTotalStar(response.data.data.total_stars);
        } else {
          console.log(response.data);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);



  useEffect(() => {

    facilatorUrl();

    const timer = setTimeout(() => {
      handleShow()
      setIsDisabled(false);
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);


  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


  // retry

  const retryActivity = () => {
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/karo-06-activity-01-screen');
      }
    })
  }



  const retryActivityApi = () => {

    let data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "karo",
      "activity_key_no": "karo06",
      "activity_no": "6"
    });

    const config = {
      method: "POST",
      url: BaseURL + "/retryActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };


    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });

  }


  var data1 = JSON.stringify({
    user_id: user_id,
  });

  const facilatorUrl = async () => {
    var config = {
      method: "post",
      url: BaseURL + "/karoFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };

    await axios(config)
      .then(function (response112) {

        if (userType == 'group') {
          setKaroFacilatorUrl(response112.data.facilator_permission)
        }


        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const nextUrlLink = () => {
    const sum = parseInt(karoIndex) + 1;

    if (userType == 'group') {
      // localStorage.setItem('karo', sum);
      // navigate(karoFacilatorUrl[sum].url);

      if (karoFacilatorUrl[sum] != undefined) {
        localStorage.setItem('karo', sum);

        navigate(karoFacilatorUrl[sum].url);
      } else {
        navigate('/board-screen');
      }

    } else {
      navigate('/karo-07-activity-01-screen')
    }
  }


  return (
    <>
      <main>
        <section className="bg-flexible blues">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">

            <div className="container-fluid cf-set">


              <div className="row align-items-center justify-content-center mt-30">
                <div className="col-lg-4">
                  <h1
                    className="bahut-khub wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    शाबाश!
                  </h1>

                  <div
                    className="six-tare mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1">आपने बहुत ही सुंदर पत्र लिखा है </h1>
                    <p className="text-center"> तारे इकट्ठे हुए </p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{totalStar}</h4>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex mt-30 gkw-img">
                    <div className="mr-20">
                      <div

                      >
                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>
                      </div>
                    </div>

                    {/* <div className="mr-20">
                               <NavLink to="/gyanbox-06-activity-screen">
                                   <center><img src="assets/img/icon/gyan-box.svg" alt="" /></center>
                                   <p className="side-btn">ज्ञान बॉक्स</p>
                               </NavLink>

                           </div> */}

                    <div>
                      <div

                      >
                        {/* <button
                      onClick={nextUrlLink}

                        className="btnset "
                      >
                        <span>अगली एक्टिविटी </span>{" "}
                      </button> */}


                        <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                          {" "}
                          {isDisabled ? <span>अगली एक्टिविटी  </span> : <span>अगली एक्टिविटी </span>}
                        </button>

                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.2s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="100%"
                      src="assets/img/karo/mera-priye-mitr.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>

            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid ">

                <div className="row align-items-top justify-content-between mt-50">
                  <div className="col-lg-6">
                    <p
                      className="mpara large-para fs-30 mt-40 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.3s"
                      style={{
                        "font-size": "2.5vw !important",
                        "line-height": "4vw",
                      }}
                    >
                      आपके मित्र या साथी लड़के भी हो सकते हैं और लड़कियाँ भी। हम दोनों
                      की खूबियाँ देख सकते हैं, इससे कोई फर्क नहीं पड़ता कि वह कौन है,लड़का या लड़की - खूबी सब में होती है और आगे बढ़ने की ताकत
                      भी।{" "}
                    </p>

                  </div>
                  <div
                    className="col-lg- wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="/karo-06-activity-04-screen" className="width-p">
                      <center>
                        <img
                          draggable="false"
                          className="mb-15"
                          width="90%"
                          src="assets/img/karo/mera-priye-mitr.svg"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Karo06Activity05Screen;
