import React, { useEffect, useState } from "react";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { NavLink, useNavigate } from "react-router-dom";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
const Khelo10Activity08Screen = () => {
  const navigate = useNavigate();
  const [totalStars, setTotalStars] = useState(0);
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');
  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');
  const [mummyPapaImage, setMummyPapaImage] = useState();
  const [mummyPapaText, setMummyPapaText] = useState();
  const [rishtedarImage, setRishtedarImage] = useState();
  const [rishtedarText, setRishtedarText] = useState();
  const [bhaiBahanImage, setBhaiBahanImage] = useState();
  const [bhaiBahanText, setBhaiBahanText] = useState();
  const [dostoImage, setDostoImage] = useState();
  const [dostoText, setDostoText] = useState();
  const [teacherImage, setTeacherImage] = useState();
  const [teacherText, setTeacherText] = useState();
  const [vidalayaImage, setVidalayaImage] = useState();
  const [vidalayaText, setVidalayaText] = useState();
  const [classImage, setClassImage] = useState();
  const [classText, setClassText] = useState();

  const [mummyPapaImage2, setMummyPapaImage2] = useState();
  const [mummyPapaText2, setMummyPapaText2] = useState();
  const [rishtedarImage2, setRishtedarImage2] = useState();
  const [rishtedarText2, setRishtedarText2] = useState();
  const [bhaiBahanImage2, setBhaiBahanImage2] = useState();
  const [bhaiBahanText2, setBhaiBahanText2] = useState();
  const [dostoImage2, setDostoImage2] = useState();
  const [dostoText2, setDostoText2] = useState();
  const [teacherImage2, setTeacherImage2] = useState();
  const [teacherText2, setTeacherText2] = useState();
  const [vidalayaImage2, setVidalayaImage2] = useState();
  const [vidalayaText2, setVidalayaText2] = useState();
  const [classImage2, setClassImage2] = useState();
  const [classText2, setClassText2] = useState();

  const [mummyPapaImage3, setMummyPapaImage3] = useState();
  const [mummyPapaText3, setMummyPapaText3] = useState();
  const [rishtedarImage3, setRishtedarImage3] = useState();
  const [rishtedarText3, setRishtedarText3] = useState();
  const [bhaiBahanImage3, setBhaiBahanImage3] = useState();
  const [bhaiBahanText3, setBhaiBahanText3] = useState();
  const [dostoImage3, setDostoImage3] = useState();
  const [dostoText3, setDostoText3] = useState();
  const [teacherImage3, setTeacherImage3] = useState();
  const [teacherText3, setTeacherText3] = useState();
  const [vidalayaImage3, setVidalayaImage3] = useState();
  const [vidalayaText3, setVidalayaText3] = useState();
  const [classImage3, setClassImage3] = useState();
  const [classText3, setClassText3] = useState();

  const [mummyPapaImage4, setMummyPapaImage4] = useState();
  const [mummyPapaText4, setMummyPapaText4] = useState();
  const [rishtedarImage4, setRishtedarImage4] = useState();
  const [rishtedarText4, setRishtedarText4] = useState();
  const [bhaiBahanImage4, setBhaiBahanImage4] = useState();
  const [bhaiBahanText4, setBhaiBahanText4] = useState();
  const [dostoImage4, setDostoImage4] = useState();
  const [dostoText4, setDostoText4] = useState();
  const [teacherImage4, setTeacherImage4] = useState();
  const [teacherText4, setTeacherText4] = useState();
  const [vidalayaImage4, setVidalayaImage4] = useState();
  const [vidalayaText4, setVidalayaText4] = useState();
  const [classImage4, setClassImage4] = useState();
  const [classText4, setClassText4] = useState();

  const [mummyPapaImage5, setMummyPapaImage5] = useState();
  const [mummyPapaText5, setMummyPapaText5] = useState();
  const [rishtedarImage5, setRishtedarImage5] = useState();
  const [rishtedarText5, setRishtedarText5] = useState();
  const [bhaiBahanImage5, setBhaiBahanImage5] = useState();
  const [bhaiBahanText5, setBhaiBahanText5] = useState();
  const [dostoImage5, setDostoImage5] = useState();
  const [dostoText5, setDostoText5] = useState();
  const [teacherImage5, setTeacherImage5] = useState();
  const [teacherText5, setTeacherText5] = useState();
  const [vidalayaImage5, setVidalayaImage5] = useState();
  const [vidalayaText5, setVidalayaText5] = useState();
  const [classImage5, setClassImage5] = useState();
  const [classText5, setClassText5] = useState();

const [isDisabled, setIsDisabled] = useState(true);



  let userType = 'user';
  let user_id = user_details.user_id;
  if (user_details.role_id == 16) {
    userType = 'group';
    user_id = groupId;
  }
  useEffect(() => {
    var data = JSON.stringify({
      user_id: user_id,
      "activity_type": "khelo",
      "activity_no": "10"
    });
    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        "type": `${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setTotalStars(response.data.data.total_stars);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    facilatorUrl();
    getAnsQues1();
    getAnsQues2();
    getAnsQues3();
    getAnsQues4();
    getAnsQues5();
    const timer = setTimeout(() => {
      handleShow();
      setIsDisabled(false);
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const retryActivity = () => {
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-10-activity-01-screen');
      }
    })
  }
  const retryActivityApi = () => {
    let data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "khelo",
      "activity_key_no": "khelo10",
      "activity_no": "10"
    });
    const config = {
      method: "POST",
      url: BaseURL + "/retryActivity",
      headers: {
        "type": `${userType}`,
        'Authorization': `Bearer ${user_details.token} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  var data1 = JSON.stringify({
    user_id: user_id,
  });
  const facilatorUrl = async() => {
    var config = {
      method: "post",
      url: BaseURL + "/kheloFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };
    await axios(config)
      .then(function (response112) {
        if (userType == 'group') {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }
        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const nextUrlLink = () => {
    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      // localStorage.setItem('khelo', sum);
      // navigate(kheloFacilatorUrl[sum].url);

      if(kheloFacilatorUrl[sum] != undefined)
      {
        localStorage.setItem('khelo', sum);

        navigate(kheloFacilatorUrl[sum].url);
      }else{
        navigate('/board-screen');
      }

    } else {
      navigate('/khelo-11-activity-01-screen')
    }
  }
  const getAnsQues1 = () => {
    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "1",
    });
    const config = {
      method: "post",
      url: BaseURL + "/getKheloActivity10ByUser",
      headers: {
        "type": `${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data.data.ans));
        if (response.data.success === true) {
          // setCheckBlankData("filled");
          const __ans = response.data.data.ans;
          const Ans_data = __ans.split(",");
          // console.log(Ans_data);
          Ans_data.map((item) => {
            //   console.log(item);
            if (item === "मम्मी पापा के साथ") {
              setMummyPapaImage("assets/img/khelo/gif/m-p-ke-samne.gif");
              // setMummyPapaText("मम्मी पापा के साथ");
              setMummyPapaText(<span className="btnset non-hover-btn mt-2">
                <span>मम्मी पापा के साथ</span></span>);
            }
            if (item === "भाई-बहन के साथ") {
              setBhaiBahanImage("assets/img/khelo/gif/bha--behen-ke-sath.gif");
              // setBhaiBahanText("भाई-बहन के साथ");
              setBhaiBahanText(<span className="btnset non-hover-btn mt-2">
                <span>भाई-बहन के साथ</span></span>);
            }
            if (item === "दोस्तों के साथ") {
              // setDostoChecked("checked");
              setDostoImage("assets/img/khelo/gif/dosto-ke-sath.gif");
              // setDostoText("दोस्तों के साथ");
              setDostoText(<span className="btnset non-hover-btn mt-2">
                <span>दोस्तों के साथ</span></span>);
            }
            if (item === "रिश्तेदारों के साथ") {
              setRishtedarImage("assets/img/khelo/gif/rishtedar-ke-sath.gif");
              // setRishtedarText("रिश्तेदारों के साथ");
              setRishtedarText(<span className="btnset non-hover-btn mt-2">
                <span>रिश्तेदारों के साथ</span></span>);
            }
            if (item === "टीचर के साथ") {
              // setTeacherChecked("checked");
              setTeacherImage("assets/img/khelo/gif/teacher-ke-samne.svg");
              // setTeacherText("टीचर के साथ");
              setTeacherText(<span className="btnset non-hover-btn mt-2">
                <span>टीचर के साथ</span></span>);
            }
            if (item === "विद्यालय में") {
              // setVidalayaChecked("checked");
              setVidalayaImage("assets/img/khelo/gif/vidhyalay-main.gif");
              // setVidalayaText("विद्यालय में");
              setVidalayaText(<span className="btnset non-hover-btn mt-2">
                <span>विद्यालय में</span></span>);
            }
            if (item === "कक्षा में") {
              // setClassChecked("checked");
              setClassImage("assets/img/khelo/gif/classmian.svg");
              // setClassText("कक्षा में");
              setClassText(<span className="btnset non-hover-btn mt-2">
                <span>कक्षा में</span></span>);
            }
          });
          // setSelectItems(Ans_data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getAnsQues2 = () => {
    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "2",
    });
    const config = {
      method: "post",
      url: BaseURL + "/getKheloActivity10ByUser",
      headers: {
        "type": `${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data.data.ans));
        if (response.data.success === true) {
          // setCheckBlankData("filled");
          const __ans = response.data.data.ans;
          const Ans_data = __ans.split(",");
          // console.log(Ans_data);
          Ans_data.map((item) => {
            //   console.log(item);
            if (item === "मम्मी पापा के साथ") {
              setMummyPapaImage2("assets/img/khelo/gif/m-p-ke-samne.gif");
              // setMummyPapaText("मम्मी पापा के साथ");
              setMummyPapaText2(<span className="btnset non-hover-btn mt-2">
                <span>मम्मी पापा के साथ</span></span>);
            }
            if (item === "भाई-बहन के साथ") {
              setBhaiBahanImage2("assets/img/khelo/gif/bha--behen-ke-sath.gif");
              // setBhaiBahanText("भाई-बहन के साथ");
              setBhaiBahanText2(<span className="btnset non-hover-btn mt-2">
                <span>भाई-बहन के साथ</span></span>);
            }
            if (item === "दोस्तों के साथ") {
              setDostoImage2("assets/img/khelo/gif/dosto-ke-sath.gif");
              // setDostoText("दोस्तों के साथ");
              setDostoText2(<span className="btnset non-hover-btn mt-2">
                <span>दोस्तों के साथ</span></span>);
            }
            if (item === "रिश्तेदारों के साथ") {
              setRishtedarImage2("assets/img/khelo/gif/rishtedar-ke-sath.gif");
              // setRishtedarText("रिश्तेदारों के साथ");
              setRishtedarText2(<span className="btnset non-hover-btn mt-2">
                <span>रिश्तेदारों के साथ</span></span>);
            }
            if (item === "टीचर के साथ") {
              setTeacherImage2("assets/img/khelo/gif/teacher-ke-samne.svg");
              // setTeacherText("टीचर के साथ");
              setTeacherText2(<span className="btnset non-hover-btn mt-2">
                <span>टीचर के साथ</span></span>);
            }
            if (item === "विद्यालय में") {
              // setVidalayaChecked("checked");
              setVidalayaImage2("assets/img/khelo/gif/vidhyalay-main.gif");
              // setVidalayaText("विद्यालय में");
              setVidalayaText2(<span className="btnset non-hover-btn mt-2">
                <span>विद्यालय में</span></span>);
            }
            if (item === "कक्षा में") {
              // setClassChecked("checked");
              setClassImage2("assets/img/khelo/gif/classmian.svg");
              // setClassText("कक्षा में");
              setClassText2(<span className="btnset non-hover-btn mt-2">
                <span>कक्षा में</span></span>);
            }
          });
          // setSelectItems(Ans_data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const getAnsQues3 = () => {
    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "3",
    });
    const config = {
      method: "post",
      url: BaseURL + "/getKheloActivity10ByUser",
      headers: {
        "type": `${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data.data.ans));
        if (response.data.success === true) {
          // setCheckBlankData("filled");
          const __ans = response.data.data.ans;
          const Ans_data = __ans.split(",");
          // console.log(Ans_data);
          Ans_data.map((item) => {
            //   console.log(item);
            if (item === "मम्मी पापा के साथ") {
              setMummyPapaImage3("assets/img/khelo/gif/m-p-ke-samne.gif");
              // setMummyPapaText("मम्मी पापा के साथ");
              setMummyPapaText3(<span className="btnset non-hover-btn mt-2">
                <span>मम्मी पापा के साथ</span></span>);
            }
            if (item === "भाई-बहन के साथ") {
              setBhaiBahanImage3("assets/img/khelo/gif/bha--behen-ke-sath.gif");
              // setBhaiBahanText("भाई-बहन के साथ");
              setBhaiBahanText3(<span className="btnset non-hover-btn mt-2">
                <span>भाई-बहन के साथ</span></span>);
            }
            if (item === "दोस्तों के साथ") {
              setDostoImage3("assets/img/khelo/gif/dosto-ke-sath.gif");
              // setDostoText("दोस्तों के साथ");
              setDostoText3(<span className="btnset non-hover-btn mt-2">
                <span>दोस्तों के साथ</span></span>);
            }
            if (item === "रिश्तेदारों के साथ") {
              setRishtedarImage3("assets/img/khelo/gif/rishtedar-ke-sath.gif");
              // setRishtedarText("रिश्तेदारों के साथ");
              setRishtedarText3(<span className="btnset non-hover-btn mt-2">
                <span>रिश्तेदारों के साथ</span></span>);
            }
            if (item === "टीचर के साथ") {
              setTeacherImage3("assets/img/khelo/gif/teacher-ke-samne.svg");
              // setTeacherText("टीचर के साथ");
              setTeacherText3(<span className="btnset non-hover-btn mt-2">
                <span>टीचर के साथ</span></span>);
            }
            if (item === "विद्यालय में") {
              // setVidalayaChecked("checked");
              setVidalayaImage3("assets/img/khelo/gif/vidhyalay-main.gif");
              // setVidalayaText("विद्यालय में");
              setVidalayaText3(<span className="btnset  non-hover-btn mt-2">
                <span>विद्यालय में</span></span>);
            }
            if (item === "कक्षा में") {
              // setClassChecked("checked");
              setClassImage3("assets/img/khelo/gif/classmian.svg");
              // setClassText("कक्षा में");
              setClassText3(<span className="btnset non-hover-btn mt-2">
                <span>कक्षा में</span></span>);
            }
          });
          // setSelectItems(Ans_data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const getAnsQues4 = () => {
    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "4",
    });
    const config = {
      method: "post",
      url: BaseURL + "/getKheloActivity10ByUser",
      headers: {
        "type": `${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data.data.ans));
        if (response.data.success === true) {
          // setCheckBlankData("filled");
          const __ans = response.data.data.ans;
          const Ans_data = __ans.split(",");
          // console.log(Ans_data);
          Ans_data.map((item) => {
            //   console.log(item);
            if (item === "मम्मी पापा के साथ") {
              setMummyPapaImage4("assets/img/khelo/gif/m-p-ke-samne.gif");
              // setMummyPapaText("मम्मी पापा के साथ");
              setMummyPapaText4(<span className="btnset non-hover-btn mt-2">
                <span>मम्मी पापा के साथ</span></span>);
            }
            if (item === "भाई-बहन के साथ") {
              setBhaiBahanImage4("assets/img/khelo/gif/bha--behen-ke-sath.gif");
              // setBhaiBahanText("भाई-बहन के साथ");
              setBhaiBahanText4(<span className="btnset non-hover-btn mt-2">
                <span>भाई-बहन के साथ</span></span>);
            }
            if (item === "दोस्तों के साथ") {
              setDostoImage4("assets/img/khelo/gif/dosto-ke-sath.gif");
              // setDostoText("दोस्तों के साथ");
              setDostoText4(<span className="btnset non-hover-btn mt-2">
                <span>दोस्तों के साथ</span></span>);
            }
            if (item === "रिश्तेदारों के साथ") {
              setRishtedarImage4("assets/img/khelo/gif/rishtedar-ke-sath.gif");
              // setRishtedarText("रिश्तेदारों के साथ");
              setRishtedarText4(<span className="btnset non-hover-btn mt-2">
                <span>रिश्तेदारों के साथ</span></span>);
            }
            if (item === "टीचर के साथ") {
              setTeacherImage4("assets/img/khelo/gif/teacher-ke-samne.svg");
              // setTeacherText("टीचर के साथ");
              setTeacherText4(<span className="btnset non-hover-btn mt-2">
                <span>टीचर के साथ</span></span>);
            }
            if (item === "विद्यालय में") {
              // setVidalayaChecked("checked");
              setVidalayaImage4("assets/img/khelo/gif/vidhyalay-main.gif");
              // setVidalayaText("विद्यालय में");
              setVidalayaText4(<span className="btnset non-hover-btn mt-2">
                <span>विद्यालय में</span></span>);
            }
            if (item === "कक्षा में") {
              // setClassChecked("checked");
              setClassImage4("assets/img/khelo/gif/classmian.svg");
              // setClassText("कक्षा में");
              setClassText4(<span className="btnset non-hover-btn mt-2">
                <span>कक्षा में</span></span>);
            }
          });
          // setSelectItems(Ans_data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const getAnsQues5 = () => {
    const data = JSON.stringify({
      user_id: user_id,
      ques_id: "5",
    });
    const config = {
      method: "post",
      url: BaseURL + "/getKheloActivity10ByUser",
      headers: {
        "type": `${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data.data.ans));
        if (response.data.success === true) {
          // setCheckBlankData("filled");
          const __ans = response.data.data.ans;
          const Ans_data = __ans.split(",");
          // console.log(Ans_data);
          Ans_data.map((item) => {
            //   console.log(item);
            if (item === "मम्मी पापा के साथ") {
              setMummyPapaImage5("assets/img/khelo/gif/m-p-ke-samne.gif");
              // setMummyPapaText("मम्मी पापा के साथ");
              setMummyPapaText5(<span className="btnset non-hover-btn mt-2">
                <span>मम्मी पापा के साथ</span></span>);
            }
            if (item === "भाई-बहन के साथ") {
              setBhaiBahanImage5("assets/img/khelo/gif/bha--behen-ke-sath.gif");
              // setBhaiBahanText("भाई-बहन के साथ");
              setBhaiBahanText5(<span className="btnset non-hover-btn mt-2">
                <span>भाई-बहन के साथ</span></span>);
            }
            if (item === "दोस्तों के साथ") {
              setDostoImage5("assets/img/khelo/gif/dosto-ke-sath.gif");
              // setDostoText("दोस्तों के साथ");
              setDostoText5(<span className="btnset non-hover-btn mt-2">
                <span>दोस्तों के साथ</span></span>);
            }
            if (item === "रिश्तेदारों के साथ") {
              setRishtedarImage5("assets/img/khelo/gif/rishtedar-ke-sath.gif");
              // setRishtedarText("रिश्तेदारों के साथ");
              setRishtedarText5(<span className="btnset non-hover-btn mt-2">
                <span>रिश्तेदारों के साथ</span></span>);
            }
            if (item === "टीचर के साथ") {
              setTeacherImage5("assets/img/khelo/gif/teacher-ke-samne.svg");
              // setTeacherText("टीचर के साथ");
              setTeacherText5(<span className="btnset non-hover-btn mt-2">
                <span>टीचर के साथ</span></span>);
            }
            if (item === "विद्यालय में") {
              // setVidalayaChecked("checked");
              setVidalayaImage5("assets/img/khelo/gif/vidhyalay-main.gif");
              // setVidalayaText("विद्यालय में");
              setVidalayaText5(<span className="btnset non-hover-btn mt-2">
                <span>विद्यालय में</span></span>);
            }
            if (item === "कक्षा में") {
              // setClassChecked("checked");
              setClassImage5("assets/img/khelo/gif/classmian.svg");
              // setClassText("कक्षा में");
              setClassText5(<span className="btnset non-hover-btn mt-2">
                <span>कक्षा में</span></span>);
            }
          });
          // setSelectItems(Ans_data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <main>
        <section className="bg-flexible F8F1FD">
          <div className="fixed-header">
            <Name />
          </div>
          <div className="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-4">
                  <h1
                    className="bahut-khub fs-m-4 mt-30 mt-m-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    बहुत खूब!
                  </h1>
                  <div
                    className="six-tare mt-30 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <h1 className="rh1">आपकी राय जान कर अच्छा लगा </h1>
                    <p className="text-center">तारे इकट्ठेे हुए</p>
                    <ul className="ul-r">
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <img src="assets/img/icon/star.svg" alt="" />
                      </li>
                      <li>
                        <h4>{totalStars}</h4>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex mt-30">
                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex"
                      >
                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>
                      </div>
                    </div>
                    <div>
                      <div
                      >
                        {/* <button
                          onClick={nextUrlLink}
                          className="btnset "
                        >
                          <span>अगली एक्टिविटी </span>{" "}
                        </button> */}


                        <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                        {" "}
                        {isDisabled ? <span>अगली एक्टिविटी </span> : <span>अगली एक्टिविटी </span>}
                      </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <center>
                    <img
                      draggable="false"
                      width="100%"
                      src="assets/img/khelo/bsbc-main-img.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal show={show} className="modal fade bd-example-modal-lg">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="addjustment-popup pt-m-3">
              <div className="container-fluid">
                <div className="">
                  <div className="row align-items-center justify-content-center ">
                    <div
                      className="col-lg-12 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.1s"
                    >
                      <p className="mpara large-para fs-30">चार्ट देखो, किससे बात करना आसान है?</p>
                      <div className="table_box">
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ height: "100%", borderRight: "2px solid black","paddingLeft":'20px' }}
                            >
                            <b>कहाँ हमें अपने विचार व्यक्त करने की इजाज़त नहीं होती?</b>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="teacher-img">
                              {/* {getAnsQues1()} */}
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={mummyPapaImage} alt="" />
                                {mummyPapaText}
                              </div>

                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={rishtedarImage} alt="" />
                                {rishtedarText}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={bhaiBahanImage} alt="" />
                                {bhaiBahanText}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={dostoImage} alt="" />
                                {dostoText}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={teacherImage} alt="" />
                                {teacherText}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={vidalayaImage} alt="" />
                                {vidalayaText}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={classImage} alt="" />
                                {classText}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr style={{ margin: 0 }} />
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ height: "100%", borderRight: "2px solid black","paddingLeft":'20px' }}
                            >
                             <b>हम कहाँ चुपचाप सुनते रहते हैं ?</b>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="teacher-img">
                            {/* {getAnsQues2()} */}
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={mummyPapaImage2} alt="" />
                                {mummyPapaText2}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={rishtedarImage2} alt="" />
                                {rishtedarText2}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={bhaiBahanImage2} alt="" />
                                {bhaiBahanText2}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={dostoImage2} alt="" />
                                {dostoText2}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={teacherImage2} alt="" />
                                {teacherText2}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={vidalayaImage2} alt="" />
                                {vidalayaText2}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={classImage2} alt="" />
                                {classText2}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr style={{ margin: 0 }} />
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ height: "100%", borderRight: "2px solid black","paddingLeft":'20px' }}
                            >
                              <b>कहाँ हम सिर्फ सवाल पूछे जाने पर बोल सकते हैं?</b>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="teacher-img">
                              {/* {getAnsQues(3)} */}
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={mummyPapaImage3} alt="" />
                                {mummyPapaText3}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={rishtedarImage3} alt="" />
                                {rishtedarText3}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={bhaiBahanImage3} alt="" />
                                {bhaiBahanText3}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={dostoImage3} alt="" />
                                {dostoText3}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={teacherImage3} alt="" />
                                {teacherText3}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={vidalayaImage3} alt="" />
                                {vidalayaText3}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={classImage3} alt="" />
                                {classText3}
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr style={{ margin: 0 }} />
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ height: "100%", borderRight: "2px solid black","paddingLeft":'20px' }}
                            >
                            <b>कहाँ हम बातचीत कर सकते हैं, अपने विचार भी दे सकते हैं, मगर हमारे बड़े ही निर्णय लेते हैं</b>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="teacher-img">
                              {/* {getAnsQues(4)} */}
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={mummyPapaImage4} alt="" />
                                {mummyPapaText4}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={rishtedarImage4} alt="" />
                                {rishtedarText4}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={bhaiBahanImage4} alt="" />
                                {bhaiBahanText4}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={dostoImage4} alt="" />
                                {dostoText4}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={teacherImage4} alt="" />
                                {teacherText4}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={vidalayaImage4} alt="" />
                                {vidalayaText4}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={classImage4} alt="" />
                                {classText4}
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr style={{ margin: 0 }} />
                        <div className="row">
                          <div className="col-md-6">
                            <div
                              className="d-flex align-items-center justify-content-center"
                              style={{ height: "100%", borderRight: "2px solid black","paddingLeft":'20px' }}
                            >
                            <b>कहाँ हम बेझिझक खुद को व्यक्त करते हैं और निर्णय हमारी सहभागिता से होते हैं</b>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="teacher-img">
                              {/* {getAnsQues(4)} */}
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={mummyPapaImage5} alt="" />
                                {mummyPapaText5}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={rishtedarImage5} alt="" />
                                {rishtedarText5}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={bhaiBahanImage5} alt="" />
                                {bhaiBahanText5}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={dostoImage5} alt="" />
                                {dostoText5}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={teacherImage5} alt="" />
                                {teacherText5}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={vidalayaImage5} alt="" />
                                {vidalayaText5}
                              </div>
                              <div style={{ display: "flex", flexFlow: "column", alignItems: "center" }}>
                                <img src={classImage5} alt="" />
                                {classText5}
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                      <p className="mpara large-para fs-30">
                        कभी-कभी हम महसूस करते हैं कि हम अपनी भावनाओं और डर के बारे में दोस्तों, छोटे भाई - बहन से आसानी से बात कर पाते हैं| लेकिन यही बातें हम अपने बड़ों से करने में संकोच महसूस करते हैं| जितनी आसानी से हम अपने दोस्तों से यह बातें कर सकते हैं, उतनी सहजता से बड़ों से बात करना भी जरूरी है
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Khelo10Activity08Screen;
