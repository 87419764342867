import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
const Khelo01Activity13Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [totalStar, setTotalStar] = useState();
  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');
  const user_details = JSON.parse(localStorage.getItem('user_details'));

  const [isDisabled, setIsDisabled] = useState(true);

  const groupId = localStorage.getItem('groupId');
  let userType = 'user';
  let user_id = user_details.user_id;
  if (user_details.role_id == 16) {
    userType = 'group';
    user_id = groupId;
  }
  useEffect(() => {
    var data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "khelo",
      "activity_no": "1"
    });
    var config = {
      method: 'post',
      url: BaseURL + '/getFinalStars',
      headers: {
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setTotalStar(response.data.data.total_stars);
        }
        //   console.log(JSON.stringify(response.data.data.totalstar));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      setIsDisabled(false);
      handleShow()
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  // retry
  const retryActivity = () => {
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-01-activity-01-screen');
      }
    })
  }
  const retryActivityApi = () => {
    let data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "khelo",
      "activity_key_no": "khelo01",
      "activity_no": "1"
    });
    const config = {
      method: "POST",
      url: BaseURL + "/retryActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  var data1 = JSON.stringify({
    user_id: user_id,
  });
  const facilatorUrl = async () => {
    var config = {
      method: "post",
      url: BaseURL + "/kheloFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };
    await axios(config)
      .then(function (response112) {
        if (userType == 'group') {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }
        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const nextUrlLink = () => {
    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      // localStorage.setItem('khelo', sum);
      // navigate(kheloFacilatorUrl[sum].url);

      if (kheloFacilatorUrl[sum] != undefined) {
        localStorage.setItem('khelo', sum);

        navigate(kheloFacilatorUrl[sum].url);
      } else {
        navigate('/board-screen');
      }

    } else {
      navigate('/khelo-02-activity-01-screen')
    }
  }
  const ShowMsg = () => {
    if (totalStar > 0) {
      return (
        <>
          <div className="six-tare mt-m-2 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
            <h1 className="rh1">सभी स्तर पूरे हो गए! </h1>
            <p className="text-center"> मुबारक! <br></br> अपने सभी तारे इकट्ठा कर लिए हैं </p>
            <ul className="ul-r">
              <li><img src="assets/img/icon/star.svg" alt="" /></li>
              <li><img src="assets/img/icon/star.svg" alt="" /></li>
              <li><img src="assets/img/icon/star.svg" alt="" /></li>
              <li><h4>{totalStar}</h4></li>
            </ul>
          </div>
        </>
      )
    } else if (totalStar <= 0) {
      return (
        <>
          <div className="six-tare mt-m-2 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
            <h1 className="rh1 red">गलत उत्तर है</h1>
            <ul className="ul-r">
              <li><img src="assets/img/icon/star.svg" alt="" /></li>
              <li><img src="assets/img/icon/star.svg" alt="" /></li>
              <li><img src="assets/img/icon/star.svg" alt="" /></li>
              <li><h4>{totalStar}</h4></li>
            </ul>
          </div>
        </>
      )
    }
  }
  return (
    <>
      <main>
        <section className="bg-flexible FFEBEB">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center ">
                <div className="col-lg-4">
                  <ShowMsg />
                  <div className="d-flex mt-30">
                    <div className="mr-20">
                      <div className="gkw-img d-flex wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>
                      </div>
                    </div>
                    <div >
                      <div className=" wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.4s">

                        {/* <button onClick={nextUrlLink} className="btnset "><span>अगली एक्टिविटी </span> </button> */}

                        <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                          {" "}
                          {isDisabled ? <span>अगली एक्टिविटी  </span> : <span>अगली एक्टिविटी </span>}
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 offset-lg-1 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                  <center><img width="90%" src="assets/img/khelo/hamara-nana-img.svg" alt="" /></center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="addjustment-popup">
              <div className="container-fluid">
                <div className="row align-items-center justify-content-center ">
                  <div
                    className="col-lg-6 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="1s"
                  >
                    <p className="mpara large-para fs-30 text-center">
                      आज जो हमने मिलकर नारे तैयार किये हैं वह तारों की टोली की पहचान
                      बन सकते हैं| आप अपने दोस्तों के साथ मिलकर इनके  जैसे और भी नारे
                      तैयार कर सकते हैं और हमारे साथ साझा कर सकते हैं।{" "}

                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Khelo01Activity13Screen;
