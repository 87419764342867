import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";

const Suno01Activity06Screen = () => {

  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-6");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-5");
  const [totalStar, setTotalStar] = useState();
  const [sunoFacilatorUrl, setSunoFacilatorUrl] = useState([]);
  const sunoIndex = localStorage.getItem('suno');
const [isDisabled, setIsDisabled] = useState(true);
  const user_details = JSON.parse(localStorage.getItem('user_details'));
  const groupId = localStorage.getItem('groupId');

  let userType = 'user';
  let user_id = user_details.user_id;

  if(user_details.role_id == 16)
  {

    userType = 'group';
    user_id =groupId;
  }

  useEffect(() => {

    var data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "suno",
      "activity_no": "1"
    });

    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'type':`${userType}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {

        console.log(response.data.data)
        if (response.data.success === true) {
          const result = response.data.data.total_stars;
          setTotalStar(result);

        }



      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow();
      setIsDisabled(false);
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);


    // retry

    const retryActivity = ()=>{
      Swal.fire({
        title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
        text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "हाँ, इसे हटा दें!"
      }).then((result) => {
        if (result.isConfirmed) {
          retryActivityApi();
          navigate('/suno-01-activity-01-screen');
        }
      })
    }



    const retryActivityApi = ()=>{

          let data = JSON.stringify({
            "user_id": user_id,
            "activity_type": "suno",
            "activity_key_no": "suno01",
            "activity_no": "1"
          });

        const config = {
            method: "POST",
            url: BaseURL + "/retryActivity",
            headers: {
              'Authorization': `Bearer ${user_details.token} `,
              'type': `${userType} `,
              'Content-Type': 'application/json'
            },
            data: data,
          };


        axios.request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));
        })
        .catch((error) => {
          console.log(error);
        });

    }

    // suno

    var data1 = JSON.stringify({
      user_id: user_id,
    });

    const facilatorUrl = async()=>{
      var config = {
        method: "post",
        url: BaseURL+"/sunoFetchAllActivity_web",
        headers: {
          'Authorization': `Bearer ${user_details.token} `,
          'type':`${userType}`,
          'Content-Type': 'application/json'
        },
        data: data1,
      };

      await axios(config)
        .then(function (response112) {


          if(userType == 'group')
          {
            setSunoFacilatorUrl(response112.data.facilator_permission)
          }


          // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    const nextUrlLink = ()=>{


        let sum = parseInt(sunoIndex) + parseInt(1);
        if (userType == 'group') {
          // localStorage.setItem('suno', sum);
          // navigate(sunoFacilatorUrl[sum].url);

          if(sunoFacilatorUrl[sum] != undefined)
          {
            localStorage.setItem('suno', sum);

            navigate(sunoFacilatorUrl[sum].url);
          }else{
            navigate('/board-screen');
          }

        } else {
          navigate('/suno-02-activity-01-screen');
        }




    }





  const ShowMsg = () => {
    if (totalStar > 0) {
      return (
        <>
          <h1
            className="bahut-khub mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            शाबाश!
          </h1>
          <div
            className="six-tare mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h1 className="rh1">आपने यह एक्टिविटी पूरा किया</h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4></h4>
              </li>
            </ul>
          </div>
        </>
      )
    } else {
      return (
        <>


          <div
            className="six-tare mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h1 className="rh1 red">गलत उत्तर है</h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>{totalStar}</h4>
              </li>
            </ul>
          </div>
        </>
      )
    }
  }


  return (
    <>
      <main>
        <section className="bg-flexible D4F3FD">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              <div className="row align-items-center justify-content-center mt-30">
                <div className="col-lg-4">
                  {/* <ShowMsg /> */}

                  <h1
            className="bahut-khub mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            शाबाश!
          </h1>
          <div
            className="six-tare mt-30 wow fadeInDown"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <h1 className="rh1">आपने ये एक्टिविटी पूरा किया</h1>
            <p className="text-center"> तारे इकट्ठे हुए </p>
            <ul className="ul-r">
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <img src="assets/img/icon/star.svg" alt="" />
              </li>
              <li>
                <h4>10</h4>
              </li>
            </ul>
          </div>

                  {/* <div class="mpara large-para fs-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.5s">
                      <img class="mt-10" src="assets/img/suno/sabas-tare-10.svg" alt="">
                  </div> */}
                  <div className="gkw-img d-flex mt-30">

                    <div className="mr-20">
                      <div
                        className="gkw-img d-flex"

                      >


                        <div className="mr-20">
                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>
                        </div>

                      </div>
                    </div>
                    <div>
                      <div
                        className=""

                      >
                        {/* <button onClick={nextUrlLink} className="btnset ">
                          <span>अगली एक्टिविटी </span>{" "}
                        </button> */}



                        <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                        {" "}
                        {isDisabled ? <span>अगली एक्टिविटी </span> : <span>अगली एक्टिविटी </span>}
                      </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 offset-lg-1 wow fadeInDown"
                  data-wow-duration="1s"
                  data-wow-delay="0.3s"
                >
                  <center>
                    <img
                      draggable="false"
                      className="mt-0"
                      width="90%"
                      src="assets/img/suno/suno-img-2.svg"
                    />
                  </center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <Modal show={show} className="modal fade bd-example-modal-lg">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>

            <div className="addjustment-popup">
              <div className="container-fluid">


                <div className="row align-items-center justify-content-center pt-70 ">


                </div>
                <div className="row align-items-center justify-content-between mt-40">
                  <div className="col-lg-7">
                    <p
                      className="mpara large-para fs-30 mt-40 wow fadeInDown"
                      data-wow-duration="1s"
                      data-wow-delay="0.2s"
                    >
                      लड़के हो या लड़कियाँ, हम उनके गुणों में कोई फर्क नहीं बता सकते, क्योंकि यह
                      गुण दोनोंं में ही हो सकते हैं। हम सब ही सोचने लगे कि एक मेंढक लड़का है और
                      एक लड़की, यह तो गड़बड़ हो गई। दोनों ही मेंढक दोनों ही हो सकते हैं, लड़का
                      भी और लड़की भी। गुणों के आधार पर हम लड़का और लड़की में भेद करना सीखते हैं।
                      हम सुनते हैं कि लड़के हमेशा बहादुर होते हैं और लड़कियाँ हमेशा कमजोर, लेकिन
                      ये सही नहीं है। लड़के और लड़कियों दोनोंं में डर या बहादुरी की भावना होती
                      है। किसी भी परिस्थिती में दोनोंं ही ये भावना महसूस कर सकते हैं। वैसे ही
                      खेल या अन्य रुझान में भी है। इसलिए ये फ़र्क सही नहीं है। लड़का लड़की दोनोंं
                      ही बराबर हैं।
                    </p>
                  </div>
                  <div
                    className="col-lg-5 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <NavLink to="#">
                      <center>
                        <img
                          draggable="false"
                          className="mb-15"
                          width="100%"
                          src="assets/img/suno/jaja-baja.svg"
                        />
                      </center>
                    </NavLink>
                  </div>
                </div>


              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>

    </>
  );
};

export default Suno01Activity06Screen;
