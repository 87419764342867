import React, { useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import Name from "../common/Name";
import axios from "axios";
import BaseURL from "../common/Baseurl";
import { Modal, Button, Form } from "react-bootstrap";
import Swal from "sweetalert2";
const Khelo02Activity04Screen = () => {
  const navigate = useNavigate();
  const [lg, setLg] = useState("col-lg-8");
  const [rowAllgn, setrowAllgn] = useState(
    "row align-items-center justify-content-center"
  );
  const [faqsize, setfaqsize] = useState("col-lg-4");
  const [totalStar, setTotalStar] = useState();
  const [kheloFacilatorUrl, setKheloFacilatorUrl] = useState([]);
  const kheloIndex = localStorage.getItem('khelo');
  const user_details = JSON.parse(localStorage.getItem("user_details"));
  const groupId = localStorage.getItem('groupId');
   const [isDisabled, setIsDisabled] = useState(true);
  let userType = 'user';
  let user_id = user_details.user_id;
  if (user_details.role_id == 16) {
    userType = 'group';
    user_id = groupId;
  }
  useEffect(() => {
    const data = JSON.stringify({
      user_id: user_id,
      "activity_type": "khelo",
      "activity_no": "2"
    });
    var config = {
      method: "post",
      url: BaseURL + "/getFinalStars",
      headers: {
        'type': `${userType} `,
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        if (response.data.success === true) {
          setTotalStar(response.data.data.total_stars)
          // console.log(response.data.data.totalstar);
        } else {
          console.log(response.data);
        }
        // console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    facilatorUrl();
    const timer = setTimeout(() => {
      handleShow()
      setIsDisabled(false);
      // navigate("/gyanbox-05-activity-screen");
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const retryActivity = () => {
    Swal.fire({
      title: "क्या आप दुबारा कोशिश   करना चाहते हैं?",
      text: "आप इसे पूर्ववत नहीं कर पाएँगे!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "हाँ, इसे हटा दें!"
    }).then((result) => {
      if (result.isConfirmed) {
        retryActivityApi();
        navigate('/khelo-02-activity-01-screen');
      }
    })
  }
  const retryActivityApi = () => {
    let data = JSON.stringify({
      "user_id": user_id,
      "activity_type": "khelo",
      "activity_key_no": "khelo02",
      "activity_no": "2"
    });
    const config = {
      method: "POST",
      url: BaseURL + "/retryActivity",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType} `,
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios.request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }
  var data1 = JSON.stringify({
    user_id: user_id,
  });
  const facilatorUrl = async() => {
    var config = {
      method: "post",
      url: BaseURL + "/kheloFetchAllActivity_web",
      headers: {
        'Authorization': `Bearer ${user_details.token} `,
        'type': `${userType}`,
        'Content-Type': 'application/json'
      },
      data: data1,
    };
    await axios(config)
      .then(function (response112) {
        if (userType == 'group') {
          setKheloFacilatorUrl(response112.data.facilator_permission)
        }
        // setKaroFacilatorUrl(response112.data.facilator_permission[sum].url)
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  const nextUrlLink = () => {
    let sum = parseInt(kheloIndex) + parseInt(1);
    if (userType == 'group') {
      // localStorage.setItem('khelo', sum);
      // navigate(kheloFacilatorUrl[sum].url);

      if(kheloFacilatorUrl[sum] != undefined)
      {
        localStorage.setItem('khelo', sum);

        navigate(kheloFacilatorUrl[sum].url);
      }else{
        navigate('/board-screen');
      }

    } else {
      navigate('/khelo-03-activity-01-screen')
    }
  }
  const ShowMsg = () => {
    if (totalStar > 0) {

      return (
        <>
        <div className="six-tare mt-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
          <h1 className="rh1">समय के भीतर ही आपने आपके  सारे उत्तर सही हैं   </h1>
          <p className="text-center"> तारे इकट्ठे हुए </p>
          <ul className="ul-r">
            <li><img src="assets/img/icon/star.svg" alt="" /></li>
            <li><img src="assets/img/icon/star.svg" alt="" /></li>
            <li><img src="assets/img/icon/star.svg" alt="" /></li>
            <li><h4>{totalStar}</h4></li>
          </ul>
          </div>
        </>
      )
    }else if(totalStar <= 0){

      return (
        <>
        <div className="six-tare mt-30 wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.2s">
          <h1 className="rh1 red">गलत उत्तर है </h1>
          <p className="text-center"> तारे इकट्ठे हुए </p>
          <ul className="ul-r">
            <li><img src="assets/img/icon/star.svg" alt="" /></li>
            <li><img src="assets/img/icon/star.svg" alt="" /></li>
            <li><img src="assets/img/icon/star.svg" alt="" /></li>
            <li><h4>{totalStar}</h4></li>
          </ul>
          </div>
        </>
      )
    }
  }
  return (
    <>
      <main>
        <section className="bg-flexible FDF2E0">
          <Name lg={lg} faqsize={faqsize} rowAllgn={rowAllgn} />
          <div class="data-adjust">
            <div className="container-fluid cf-set">
              {/* <div className="row align-items-center justify-content-center">
                   <div className="col-lg-7 ">
                       <h2 className="h2 kkh-text"> <img className="mr-20 kkh-home-img" src="assets/img/icon/home.png" alt="" />कैसे खेलें ?</h2>
                   </div>
                   <div className="col-lg-5">
                       <a className="float-right width-auto" href="pas-information-screen.html"> <img className="close-icon" src="assets/img/icon/close.svg" alt="" /></a>
                   </div>
               </div> */}
              <div className="row align-items-top justify-content-between mt-50">
                <div className="col-lg-3 wow fadeInDown" data-wow-duration="1s">
                  {
                    (totalStar > 0) ? <>
                      <div className="timer">
                        <div className="content-alarm">
                          <p className="green">शाबाश!!</p>
                        </div>
                      </div>
                    </> : ""
                  }
                </div>
                <div className="col-lg-5">

                    <ShowMsg />

                  <div className="d-flex justify-content-center mt-30 gkw-img">
                    <div className="mr-20">
                      <div className=" d-flex">
                        <NavLink onClick={retryActivity} to="#">
                          <center>
                            <img src="assets/img/icon/wapas-jayen.svg" alt="" />
                          </center>
                          <p className="side-btn">दुबारा कोशिश   करें</p>
                        </NavLink>
                      </div>
                    </div>
                    <div >
                      <div className="">
                        {/* <button onClick={nextUrlLink} className="btnset "><span>अगली एक्टिविटी </span> </button> */}

                        <button onClick={nextUrlLink} disabled={isDisabled} className="btnset ">
                          {" "}
                          {isDisabled ? <span>अगली एक्टिविटी  </span> : <span>अगली एक्टिविटी </span>}
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4  wow fadeInDown" data-wow-duration="1s" data-wow-delay="0.3s">
                  <center><img draggable="false" width="80%" src="assets/img/khelo/pas-main-img.svg" /></center>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Modal show={show} className="modal fade bd-example-modal-lg modal2">
        <Modal.Body>
          <>
            <button
              type="button"
              className="close c-newp"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <span aria-hidden="true">×</span>
            </button>
            <div className="addjustment-popup">
              <div className="container-fluid">
                <div className="row align-items-top justify-content-between mt-50">
                  <div
                    className="col-lg-7 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.2s"
                  >
                    <p className="mpara large-para fs-30 mt-40">
                      क्या हुआ? क्या आपने अच्छे से पूरा पेज पढ़ा? अगर हाँ तो आपको
                      पूरे तारे मिलेंगे और अगर नहीं तो आपको अपनी आदतों में कुछ बदलाव
                      लाने की ज़रूरत है। सबसे पहले यह समझना है कि हमें जो भी बोला जाए
                      उसे ध्यान लगा कर पढ़ना और सुनना बहुत ज़रूरी होता है। और जब हम
                      ध्यान लगा कर पढ़ते हैं या सुनते हैं तो काफ़ी मेहनत भी कम हो जाती
                      है जैसा कि आपने देखा, हमें बस 5 सवालों के ही उत्तर देने थे। इस
                      एक्टिविटी से हमें पता चला कि हमें हमेशा ध्यान लगा कर पढ़ना एवं
                      सुनना चाहिए। क्या आप एक बार फिर से ट्राई करना चाहते हैं? अगर हाँ, तो नीचे दिए गए दुबारा कोशिश करें बटन को दबाएँ और सही उत्तर दें।{" "}




                    </p>
                    <NavLink to="/khelo-03-activity-01-screen" className="btnset ">
                      <span>आगे बढ़ें</span>{" "}
                    </NavLink>
                  </div>
                  <div
                    className="col-lg-4 offset-lg-1 wow fadeInDown"
                    data-wow-duration="1s"
                    data-wow-delay="0.3s"
                  >
                    <center>
                      <img
                        draggable="false"
                        width="80%"
                        src="assets/img/khelo/pas-main-img.svg"
                      />
                    </center>
                  </div>
                </div>
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Khelo02Activity04Screen;
